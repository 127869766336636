.hero-section {
  background-image: url('/core_value.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  position: relative;
  padding: 0;
}

/* Bootstrap's container-padding and row-padding can affect these, so adjust if necessary */
.hero-section .container, 
.hero-section .row, 
.hero-section .col {
  height: 100%;
  padding: 0;
  margin: 0;
}

.hero-text {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.hero-text h4 {
  color: #fff;
  font-size: 24px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin-bottom: 42px;
  font-family: 'Poppins';
}

.hero-text p {
  font-size: 24px;
  font-family: 'Poppins';
  color: #fff;
  text-align: center;
}

/* Responsive adjustments */
@media (max-width: 767px) {
  .hero-section {
      padding: 50px 20px; /* Adjust padding for smaller devices */
  }
  
  .hero-text h4,
  .hero-text p {
      font-size: 14px; /* Adjust font size for smaller devices */
  }
}
