.background-container {
    display: flex;
    height: 50vw;
    max-height: 593px;
    width: 100%;
    background-image: url("/gallery.jpg"); /* Default state */
    background-size: cover;
    background-position: center center;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    align-items: flex-start;
    align-content: flex-start;
    margin-bottom: 125px;
}

.overlay {
    height: 50vw;
    max-height: 593px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: 0.4;
}

.header-title {
    color: #FFF;
    font-family: 'Poppins';
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    text-align: start;
    margin-left: 96px;
    margin-bottom: 60px;
    z-index: 1;
}

.my-masonry-grid {
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
}

/* General styles for the Masonry grid */
.my-masonry-grid_column {
    padding-left: 15px; /* Gutter size */
    background-clip: padding-box;
}

/* General styles for images in the grid */
.gallery-item img {
    width: 100%; /* Fill the column width */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 30px; /* Space between items */
    transition: transform 0.3s ease; /* Smooth transition for hover effects */
    object-fit: cover; /* Ensure the images cover the area without stretching */
}

/* Hover effect for scaling images slightly */
.gallery-item img:hover {
    transform: scale(1.03);
}
  
.gallery-container {
    padding: 20px;
}

/* You can add media queries for responsiveness if needed */
@media (max-width: 700px) {
    .my-masonry-grid_column {
        padding-left: 10px;
    }

    .header-title {
        font-size: 5vw; /* Larger font size on small screens */
        margin-left: 20px;
        margin-bottom: 30px;
    }
}
    
@media (max-width: 500px) {
    .my-masonry-grid_column {
        padding-left: 5px;
    }
}
