.footer {
    background-color: #414449; 
    color: #fff; /* Light text color for contrast */
    padding: 20px 0; /* Adjust padding to match the compact style */
    font-size: 0.9rem; /* Adjust font size as needed */
    width: 100%;
}

.footer h5 {
    font-family: 'Poppins';
    color: #fff; /* Lighter color for headings */
    margin-bottom: 15px; /* Space below headings */
}

.footer p, .footer ul, .footer ul li, .footer ul li .useful-link {
    font-family: 'Nunito';
    color: #fff; /* Lighter text color for the content */
}

.footer ul {
    padding-left: 0; /* Remove default padding */
    list-style: none; /* Remove default list style */
}

.footer ul li .useful-link {
    text-decoration: none; /* Remove underline from links */
    color: #fff; /* Set link color */
}

.footer ul li .useful-link:hover {
    text-decoration: underline; /* Underline links on hover for better UX */
}

.footer-logo {
    max-width: 100px; /* Adjust the logo size to match the screenshot */
    margin-bottom: 15px; /* Space below the logo */
}

.contact-info .contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* Or whatever spacing you prefer */
}

.contact-info .contact-icon {
    color: #fff; 
    font-size: 1em; /* Reduced from 1.2em to 1em */
    width: 15%;
}

.contact-info .contact-text {
    margin: 0; /* Resets any default margin */
    width: 85%;
    line-height: 1; /* Ensures that line height does not push the text away from center alignment */
}

.social-icons {
    font-size: 1.2rem; /* Larger icons */
    padding-bottom: 20px;
}

.social-icons a {
    color: #fff; /* Set the icon color */
    padding: 4px;
}

.social-icons a:hover {
    color: lightgrey; /* Lighten icon color on hover */
}

/* Style for the columns to include dividing lines */
.footer-col {
    border-right: 1px solid #fff; /* Adjust color to a slightly lighter shade than the footer background */
}

/* Remove the border from the last column */
.footer-col:last-child {
    border-right: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .footer-col {
        border-right: none; /* Remove borders on smaller screens */
        border-bottom: 1px solid #fff; /* Add horizontal border for stacked layout */
    }

    .footer-col:last-child {
        border-bottom: none;
    }
}

.footer-bottom-col {
    border-top: 1px solid #fff; /* Adjust color to a slightly lighter shade than the footer background */
    padding-top: 20px; /* Adjust padding to provide some space above the content */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .footer-bottom-col {
        border-top: none; /* You may choose to remove the border on smaller screens */
    }
}