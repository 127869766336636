.header-section {
    display: flex;
    height: 50vw;
    max-height: 593px;
    width: 100%;
    background-image: url("/pastor.jpg");
    background-size: cover;
    background-position: center center;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    align-items: flex-end;
    align-content: flex-start;
    margin-bottom: 20px;
}

.overlay {
    height: 50vw; /* Responsive height */
    max-height: 593px; /* Maximum height */
    position: absolute; /* Positioned relative to .header-section */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: 0.4;
}

.header-title {
    color: #fff;
    font-family: 'Poppins';
    font-size: 64px; /* Original font size */
    font-style: normal;
    font-weight: 500;
    text-align: start;
    margin-left: 96px;
    margin-bottom: 60px;
    z-index: 1;
}

.card-body-container h5 {
    font-size: 16px; /* This is a starting point */
    font-weight: bold;
    color: #333;
    font-family: 'Nunito';
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limits text to two lines */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    margin: 0; /* Adjusts the margin to fit the card */
    padding: 0 !important; /* Horizontal padding */
    height: 4.8rem; /* Sets a fixed height for two lines of text */
}

.card-override {
    height: fit-content!important;
}

.mb-3 {
    display: flex;
    justify-content: center;
}

.modal-block .modal-content {
    display: block !important; 
}

.modal-block .modal-content .modal-header .modal-title h4 {
    font-family: 'Poppins';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
}

@media (max-width: 768px) {
    .header-title {
        font-size: 5vw; /* Larger font size on small screens */
        margin-left: 20px;
        margin-bottom: 30px;
    }
}

