.outreach {
    text-align: center;
  }
  
.card-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.card {
    position: relative;
    width: 356px;
    height: 248px;
    margin: 15px;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    overflow: hidden;
}

.card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(2, 33, 71, 0.9); /* #022147 with 90% opacity */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.card:hover .card-overlay {
    background: rgba(2, 33, 71, 0.65); /* Opacity drops to 75% on hover */
    transform: scale(1.05);
}

.card-title h5 {
    margin: 0;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 175% */
    text-transform: uppercase;
    color: white;
}

.card-container div p {
    text-align: center;
    width: 283px;
    font-family: 'Nunito';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: white;
}  

.giving-card-container {
    display: flex;
    justify-content: center;
    background-color: #586261;
    border-radius: 25px;
    padding: 20px;
    transition: transform 0.3s ease;
    width: 300px;
    height: 260px;
    float: right;
    flex-direction: column;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin-left: 20px;
}

.giving-card-container h5 {
    color: #FFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
}

.giving-card-container div p {
    color: #FFF;
    text-align: center;
    font-family: 'Nunito';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.giving-card-container:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.3);
}

.content-container h3 {
    color: #000;
    font-family: 'Poppins';
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
}

.content-container ul li, ol li, p {
    color: #000;
    text-align: justify;
    font-family: 'Nunito';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.list-item p {
    color: #414449;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    margin-left: 20px;
    margin-bottom: 0px;
}

.list-group-item.active {
    background-color: #ADB1B7 !important;
    border-right-color: #5E5E5E !important;
    border-right-width: 8px !important;
    border-radius: 0px 0px 0px 0px !important;
    border-top-style: none !important;
    border-bottom-style: none !important;
}

.list-group-item.active p {
    color: #fff;
    text-align: center;
    margin-left: 0px;
}

.list-group-item {
    border: 0px !important;
    border-radius: 0px !important;
    border-right: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color) !important;
    
}

@media (max-width: 768px) {
    .sidebar-menu {
      display: none;
    }
}