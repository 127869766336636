.sermon-highlights h2 {
    color: #414449;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    padding-bottom: 42px;
}

.sermon-highlights {
    max-width: 1200px;
    margin: auto;
    padding: 75px;
}

.video-item {
    position: relative;
    cursor: pointer;
    margin-bottom: 20px;
    background: #000;
    display: block;
    width: 100%;
}

.iframe-wrapper {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
}

.iframe-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.video-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(390px, 1fr));
    gap: 20px;
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #022147D9; 
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    padding: 10px;
    z-index: 2;
}

.video-item:hover .video-overlay {
    opacity: 0.85;
}

.video-overlay h3 {
    text-align: center;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    margin: 0; 
}

.video-overlay p {
    text-align: center;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    margin: 0; 
}

@media (max-width: 600px) {
    .video-grid {
        grid-template-columns: 1fr;
    }
}

.more-button-container {
    text-align: right;
    margin-top: 20px;
}

  
.more-button {
    display: inline-flex;
    padding: 10px 37px;
    justify-content: center;
    align-items: flex-start;
    background-color: transparent;
    border: 1px solid #414449;
    cursor: pointer;
    outline: none;
    color: #414449;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-decoration: none;
}

.more-button:hover,
.more-button:focus,
.more-button:active {
    background-color: rgba(65, 68, 73, 0.1); /* Subtle tint for the button */
}
  
