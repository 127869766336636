.quick-links-container {
    position: relative; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 926px; /* Original fixed width */
    height: 353px; /* Original fixed height */
    margin: 0 auto; /* Center the container on the page */
    gap: 123px; /* Original gap */
}

.quick-links-container::before,
.quick-links-container::after {
    content: '';
    position: absolute;
    left: 33.333%; 
    top: 50%; 
    width: 1px;
    height: 273px;
    background: rgba(88, 98, 97, 0.50);
    transform: translateY(-50%); 
}

.quick-links-container::after {
    left: 66.666%; 
}

.quick-links-column {
    position: relative;
    flex: 1; 
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.quick-links-title {
    color: #414449;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
}

.quick-links-paragraph {
    color: #414449;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    line-height: 28px;
    margin-top: 24px;
    margin-bottom: 24px;
}

.quick-links-button {
    display: inline-flex;
    padding: 10px 37px;
    justify-content: center;
    align-items: flex-start;
    background-color: transparent;
    border: 1px solid #414449;
    cursor: pointer;
    outline: none;
        color: #414449;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-decoration: none;
}

.quick-links-button:hover,
.quick-links-button:focus,
.quick-links-button:active {
    background-color: rgba(65, 68, 73, 0.1);
}

/* Responsive adjustments */
@media (max-width: 991px) {
    .quick-links-container {
        flex-direction: column;
        width: auto; /* Take the full width */
        height: auto; /* Adjust height automatically */
        gap: 20px; /* Smaller gap for smaller screens */
        padding: 20px; /* Padding instead of gap for spacing */
    }

    .quick-links-container::before,
    .quick-links-container::after {
        display: none; /* Remove dividers on smaller screens */
    }

    .quick-links-column {
        /* Columns take full width on smaller screens */
        width: 100%;
        box-sizing: border-box; /* Include padding in width calculation */
    }
}
