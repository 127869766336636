.slide-content.carousel-caption {
    top: 50%;
    bottom: initial;
    transform: translateY(-50%);
    text-align: center;
    left: 50%;
    right: initial;
    transform: translate(-50%, -50%);
    position: absolute;
}

/* Use relative units for better scalability */
.slide-content h1 {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 4.06rem; /* Adjusted from 65px */
    font-weight: 700;
    line-height: 4.88rem; /* Adjusted from 78px */
    text-transform: uppercase;
    margin: 0;
    padding: 0;
}

.slide-content h4 {
    color: #FFF;
    font-family: 'Nunito', sans-serif;
    font-size: 1.13rem; /* Adjusted from 18px */
    font-weight: 700;
    line-height: 1.63rem; /* Adjusted from 26px */
    margin: 10px 0;
}

.slide-content p {
    color: #FFF;
    font-family: 'Nunito', sans-serif;
    text-align: center;
    font-size: 1.13rem; /* Adjusted from 18px */
    font-weight: 400;
    line-height: 1.63rem; /* Adjusted from 26px */
    margin: 0 0 20px;
}

.carousel-button {
    display: inline-flex;
    padding: 0.63rem 2.31rem; /* Adjusted from 10px 37px */
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid #FFF;
    margin-top: 20px;
    cursor: pointer;
    text-align: center;
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 0.88rem;
    font-weight: 600;
    line-height: 1.75rem;
    letter-spacing: 0.12rem;
    text-transform: uppercase;
    text-decoration: none;
}

.carousel-button:hover,
.carousel-button:focus,
.carousel-button:active {
    background-color: rgba(255, 255, 255, 0.1);
    outline: none;
}

.carousel-button a {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 0.88rem;
    font-weight: 600;
    line-height: 1.75rem;
    letter-spacing: 0.12rem;
    text-transform: uppercase;
    text-decoration: none;
    display: block;
}


.material-icons {
    font-size: 4.5rem; /* Adjusted for scalability */
}

/* Responsive adjustments */
@media (max-width: 992px) {
    .slide-content h1 {
        font-size: 3rem; /* Smaller size for h1 on medium devices */
        line-height: 3.56rem;
    }

    .slide-content h4,
    .slide-content p {
        font-size: 1rem; /* Smaller size for text on medium devices */
        line-height: 1.5rem;
    }

    .carousel-button {
        padding: 0.63rem 1.5rem; /* Smaller padding for buttons on medium devices */
    }
}

@media (max-width: 768px) {
    .slide-content h1 {
        font-size: 2rem; /* Even smaller size for h1 on small devices */
        margin-top: 30px;
    }

    .slide-content h4,
    .slide-content p {
        font-size: 0.88rem; /* Even smaller size for text on small devices */
        line-height: 1.25rem;
    }

    .carousel-button {
        padding: 0.63rem 1.25rem; /* Smaller padding for buttons on small devices */
    }
}

@media (max-width: 576px) {
    .slide-content {
        padding: 0 0.94rem; /* Adjusted for smallest screens */
    }

    .slide-content h1 {
        font-size: 0.75rem; /* Even smaller for very small devices */
        line-height: 1.88rem;
    }

    .slide-content h4,
    .slide-content p {
        font-size: 0.7rem; /* Even smaller for text on very small devices */
        line-height: 1rem;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .carousel-button {
        font-size: 0.375rem; /* Smaller font size for links inside buttons on very small devices */
        letter-spacing: 0.05rem;
        padding: 0.125rem 0.5rem;
        margin-top: 4px;
    }

    .material-icons {
        font-size: 2rem;
    }
}

.custom-carousel a {
    text-decoration: none;
}
