/* Contact.css */
.background-image {
    display: flex;
    height: 50vw;
    max-height: 593px;
    width: 100%;
    background-image: url("/contact.jpg");
    background-size: cover;
    background-position: center center;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    align-items: flex-start;
    align-content: flex-start;
    margin-bottom: 125px;
}

.overlay {
    height: 50vw;
    max-height: 593px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: 0.4;
}

.header-title {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    text-align: start;
    margin-left: 96px;
    margin-bottom: 60px;
    z-index: 1;
}

.contact-form-container {
    border-radius: 25px;
    background-color: #414449;
    color: #FFF; /* Set text color to white for better readability */
    box-shadow: 3px 5px 10px 0px rgba(0, 0, 0, 0.30);
}

.contact-details-container {
    background-color: #586261; /* Use the background color from your design */
    border-radius: 25px;
    padding: 48px; /* Adjust padding as needed */
    color: white;
}

.contact-details-container h2 {
    font-family: 'Poppins';
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 20px;
    font-size: 1.5rem; /* Adjust the font size as needed */
}

.contact-details-container p {
    font-family: 'Nunito';
    font-size: 14px;
    font-style: normal;
    color: #FFF;
    font-weight: 400;
    margin-bottom: 16px; /* Space between paragraphs and other elements */
    line-height: 1.4; /* Adjust line height for better readability */
    margin-bottom: 60px;
}

.info {
    margin-bottom: 16px; /* Space between info items */
}

.info .icon {
    margin-right: 16px; /* Space between icon and text */
    font-size: 1.25rem; /* Adjust icon size as needed */
}

.info p {
    margin-bottom: 0; /* Remove margin from paragraphs inside info to avoid extra space */
}

.social-icons-contact{
    display: flex;
    justify-content: center; /* Centers icons horizontally */
    align-items: center; /* Centers icons vertically */
    height: 48px; /* Set a specific height if you want to increase the area for vertical centering */
    margin-top: 100px; 
}
  
.contact-details-container span{
    color: #FFF;
    font-family: 'Nunito';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.contact-form {
    background-color: transparent; /* Override if needed */
    border: none; /* Remove any default border */
    padding: 24px; /* Match the padding with contact details */
}

.contact-form-container {
    margin-left: 96px;
    margin-right: 96px;
    margin-bottom: 97px;
}

.form-check label {
    font-family: 'Nunito' !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
}

/* Update the input fields to match the design */
.contact-form .form-control {
    background-color: #fff; /* Set a white background for the input fields */
    border: 2px solid #ccc;
    border-radius: 0; /* Keep sharp corners as per the design */
    margin-bottom: 15px;
    font-family: 'Nunito';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

/* Update the focus state */
.contact-form .form-control:focus {
    border-color: #666;
}

/* Update the submit button to match the design */
.contact-form .btn-primary {
    background-color: #586261;
    border: none;
    padding: 10px 20px;
    text-transform: uppercase;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    margin-top: 20px;
}

/* Hover state for the button */
.contact-form .btn-primary:hover {
    color: #FFF;
    background: #6e7a83; 
}

.social-icons-contact a:hover {
    color: lightgrey; /* Lighten icon color on hover */
}

.social-icons-contact a {
    color: #fff; /* Set the icon color */
    padding: 4px;
}

/* Ensure the social icons and other details match the design */
.info .icon, .social-icons-contact .icon {
    color: #FFF; /* White icons */
}

/* Adjust the icon size if needed */
.info .icon, .social-icons-contact a {
    font-size: 24px; /* Example size, adjust as needed */
}

/* New styles for the map text and container */
.map-text {
    color: #414449;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 20px;
}

.map-container {
    display: flex;
    justify-content: center;
    padding: 16px;
    margin-bottom: 160px;
}

/* Styles for the iframe */
.map-iframe {
    width: 1110px;
    height: 450px;
    border-radius: 3px;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.05);
    background: rgba(255, 255, 255, 0.00);
}

.field-text {
    color: #FFF;
    font-family: 'Nunito';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}

#formFirstName, #formLastName, #formEmail, .PhoneInputInput, #formMessage{
    border: 0;
    color: #FFF;
    border-bottom: 1px solid #fff; /* Change color as needed */
    border-radius: 0;
    box-shadow: none;
    background: transparent;
}

.error {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: var(--bs-form-invalid-color);
}

.PhoneInputInput {
    height: 35px;
    margin-bottom: 15px;
}

@media (max-width: 768px) {
    /* Adjustments for medium-sized screens, like tablets */
    .contact-form-container {
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 30px;
    }

    .contact-form .row,
    .contact-form .col {
        /* Make rows and columns stack vertically */
        flex-direction: column;
        align-items: stretch;
    }

    .contact-form .form-group,
    .contact-form .form-control,
    .PhoneInputInput {
        /* Ensure form elements take full width */
        width: 100%;
    }

    .map-iframe {
        /* Make the map iframe responsive */
        width: 100%;  /* Adjust based on your container's padding/margin */
        height: 300px; /* Adjust height as needed */
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media (max-width: 480px) {
    /* Additional adjustments for small screens, like mobile phones */
    .contact-form-container {
        margin-left: 10px;
        margin-right: 10px;
    }

    .map-iframe {
        width: calc(100% + 20px);
        margin-left: -10px;
        margin-right: -10px;
        height: 250px;
    }

    .header-title {
        font-size: 5vw; /* Larger font size on small screens */
        margin-left: 20px;
        margin-bottom: 30px;
    }
}