.ministry-snippet-container {
  background: #414449;
  padding: 0;
}

.ministry-image-container {
  background-image: url('/ministry_building.jpg');
  background-size: cover;
  background-position: center;
}

.ministry-snippet-container-row {
  margin-left: 0px;
  margin-right: 0px;
}

.ministry-content-container {
  padding: 20px;
  box-sizing: border-box;
}

.ministry-header,
.ministry-paragraph,
.ministry-button {
  width: 100%;
}

.ministry-header h2 {
  color: #FFF;
  font-family: 'Poppins';
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  text-align: left;
  padding-bottom: 22px;
  margin-left: 102px;
  margin-right: 96px;
  margin-top: 84px;
}

.ministry-paragraph p {
  color: #FFF;
  font-family: 'Nunito';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: justify;
  padding-bottom: 22px;
  margin-left: 102px;
  margin-right: 96px;
}

.ministry-button {
  padding-left: 102px;
  margin-bottom: 72px;
}

.ministry-button .btn-custom{
  background: #586261;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  color: #FFF;
  text-align: center;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 214.286% */
  letter-spacing: 1.5px;
  text-transform: uppercase;
  border-radius: 5px;
  text-decoration: none;
}

.ministry-button .btn-custom:hover {
  color: #FFF;
  background: #6e7a83; 
}

@media (max-width: 767px) {
  .ministry-snippet-container {
    padding: 0;
  }
  
  .ministry-image-container, .ministry-content-container {
    width: 100%;
  }
  
  .ministry-content-container {
    padding: 20px;
  }
}

