.gallery-container h2 {
    color: #414449;
    text-align: center;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    padding-top: 72px;
    padding-bottom: 32px;
}

.gallery-container p {
    color: #555B62;
    text-align: center;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    padding-bottom: 42px;
}

.my-masonry-grid {
    display: flex;
    margin-left: -30px; /* Adjust the negative margin to match the column gap */
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 30px; /* Space between columns */
    background-clip: padding-box;
}

.image-thumbnail {
    /* Your styles for the thumbnail */
    margin-bottom: 30px; /* space between items */
    width: 100%; /* Or adjust to the desired thumbnail width */
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }

@media (min-width: 576px) {
    .masonry-grid .image-thumbnail {
        flex-basis: calc(50% - 1rem);
    }
}
  
@media (min-width: 768px) {
    .masonry-grid .image-thumbnail {
        flex-basis: calc(33.333% - 1rem);
    }
}
  
@media (min-width: 992px) {
    .masonry-grid .image-thumbnail {
        flex-basis: calc(25% - 1rem);
    }
}

.masonry-grid img {
    max-width: 100%;
    margin-bottom: 1rem;
    display: block;
}

.gallery-container {
    margin-top: 1rem;
}

.image-thumbnail:hover {
    transform: scale(1.03);
}

.gallery-container {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.icon-container {
    display: flex;
    justify-content: center;
    margin-top: 20px; /* Or however much space you want */
    margin-bottom: 64px;
}

.gallery-icon-link {
    color: #586261; /* Normal state color */
    transition: color 0.3s ease; /* Smooth transition for hover effect */
}

.gallery-icon-link:hover {
    color: #6e7a83; /* Hover state color */
}


  