* {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0;
}

.logo {
    width: auto; /* Adjust width automatically */
    height: 100%; /* Make height responsive to the parent element */
    max-height: 187px; /* Maximum height */
    max-width: 100%; /* Make sure the logo doesn't exceed the width of its container */
}

.mynavbar .container a{
    --bs-navbar-brand-margin-end: auto;
}

.mynavbar {
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.80), transparent); /* default state */
    transition: background 0.3s ease-in-out; 
}

.navbar-scrolled {
    background-image: none;
    background-color: #414449; /* scrolled state */
}

.nav-link,
.navbar-brand {
    color: white !important; 
}

.mynavbar .dropdown-item {
    color: #414449 !important;
}

.mynavbar .dropdown-item.active, 
.mynavbar .dropdown-item:active {
    background-color: white !important;
}

.mynavbar .dropdown-item:hover,
.mynavbar .dropdown-item:focus { 
    background-color: lightgrey !important; 
}

.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

@media (max-width: 992px) {
    .navbar-nav .nav-link {
        padding-left: 0.5rem; /* Reduce padding for nav items on tablet sizes */
        padding-right: 0.5rem;
    }
    
    /* Reduce the size of the brand/logo on smaller screens */
    .navbar-brand img {
        max-height: 80px;
    }
}

@media (max-width: 768px) {
    .navbar-nav .nav-link {
        padding-left: 0.5rem; /* Further reduce padding for nav items on mobile sizes */
        padding-right: 0.5rem;
    }

    /* Even smaller logo for mobile screens */
    .navbar-brand img {
        max-height: 48px;
    }

    /* Adjust navbar padding and dropdown styles for mobile sizes */
    .mynavbar {
        padding: 0.5rem 1rem;
    }

    .mynavbar .dropdown-menu {
        position: static;
        float: none;
    }
}

@media (max-width: 480px) {
    .navbar-nav .nav-link {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        font-size: 14px; /* Slightly larger font for readability */
    }

    .navbar-brand img {
        max-height: 36px; /* Smaller logo for very small screens */
    }

    .mynavbar {
        padding: 0.25rem 0.5rem;
    }

    .mynavbar .dropdown-menu {
        position: static;
        width: 100%; /* Full width dropdowns for clarity */
    }

    .custom-toggler .navbar-toggler-icon {
        width: 32px; /* Adjust the width as needed */
        height: 32px; /* Adjust the height as needed */
        background-size: 60%; /* Adjust the background-size as needed */
        background-position: center; /* Center the icon within the button */
        background-repeat: no-repeat; /* Prevent the icon from repeating */
    }
}

.mynavbar.expanded {
    background-image: none;
    background-color: #414449; /* Or any solid color you prefer */
}