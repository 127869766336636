.about-gcbc-content h3 {
    color: #000;
    font-family: 'Poppins';
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
}

.about-gcbc-content p {
    color: #000;
    text-align: justify;
    font-family: 'Nunito';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.about-gospel-content h3 {
    color: #000;
    font-family: 'Poppins';
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
}

.about-gospel-content p {
    color: #000;
    text-align: justify;
    font-family: 'Nunito';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.about-history-content h3 {
    color: #000;
    font-family: 'Poppins';
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
}

.about-history-content p {
    color: #000;
    text-align: justify;
    font-family: 'Nunito';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.meet-pastor-container h3 {
    margin: 0;
    color: #000;
    font-family: 'Poppins';
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
}

.pastor-text p {
    color: #000;
    text-align: justify;
    font-family: 'Nunito';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.doctine-content h3 {
    color: #000;
    font-family: 'Poppins';
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
}

.doctine-content p {
    color: #000;
    text-align: justify;
    font-family: 'Nunito';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.doctrinal-statements .div-header {
    margin-left: 0px;
}
.doctrinal-statements .div-header h4{
    margin: 0;
    color: #000;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    text-align: left;
    font-weight: 300;
}

.accordion-body li {
    color: #000;
    text-align: justify;
    font-family: 'Nunito';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.doctrinal-statements p {
    color: #000;
    text-align: justify;
    font-family: 'Nunito';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.doctrine-content p {
    color: #000;
    text-align: justify;
    font-family: 'Nunito';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.list-item p {
    color: #414449;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 40px;
    margin-left: 20px;
    margin-bottom: 0px;
}

.list-group-item.active {
    background-color: #ADB1B7 !important;
    border-right-color: #5E5E5E !important;
    border-right-width: 8px !important;
    border-radius: 0px 0px 0px 0px !important;
    border-top-style: none !important;
    border-bottom-style: none !important;
}

.list-group-item.active p {
    color: #fff;
    text-align: center;
    margin-left: 0px;
}

.list-group-item {
    border: 0px !important;
    border-radius: 0px !important;
    border-right: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color) !important;
    
}

@media (max-width: 768px) {
    .sidebar-menu {
      display: none;
    }
}